<template>
  <div class="fixed-bottom">
    <div class="topo bg-dark pb-1">
      <div v-if="isOpen == true" class="backdiv"></div>
      <b-row class="my-1">
        <b-col class="text-center">
          <div
            @click="openMenuLateral()"
            :class="`rounded-circle icone mt-2 ml-3 mb-2 ${invisibleClass}`"
          >
            <font-awesome-icon
              :icon="['fa', 'bars']"
              class="mt-3 text-secondary"
            />
          </div>
        </b-col>
        <b-col class="text-center" cols="5">
          <radial-menu
            :style="`margin-left:28%;margin-top:10px;background-color:${color} ; color:#fff`"
            :itemSize="60"
            :size="50"
            :radius="90"
            :angle-restriction="180"
            @open="open"
            @close="close"
            v-if="decoded.tipo == 'Consultor' && decoded.id_empresa != 117"
          >
            <radial-menu-item
              v-for="(item, index) in items"
              :key="index"
              style="background-color: #348aa7"
              @click="() => handleClick(item)"
            >
              <span class="texto">{{ item }}</span>
            </radial-menu-item>
          </radial-menu>
        </b-col>
        <b-col class="text-center">
          <div :class="`rounded-circle icone mt-2 ml-3 mb-2 ${invisibleClass}`">
            <font-awesome-icon
              :icon="['fa', 'bell']"
              class="mt-3 text-secondary"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { RadialMenu, RadialMenuItem } from 'vue-radial-menu';
export default {
  name: 'DashBoard',
  props: {
    decoded: Object,
  },
  data() {
    return {
      isOpen: false,
      invisibleClass: '',
      color: '#348aa7',
      //items: ["Cotação", "PayPag", "Vistoria"],
      items: ['Cotação', 'Pré Cotação'],
      /* items: ['Cotação'], */
      lastClicked: 'click on something!',
    };
  },
  methods: {
    openMenuLateral() {
      this.$emit('OpenMenuLateral', true);
    },
    handleClick(item) {
      this.lastClicked = item;
      if (item == 'Cotação') {
        this.$router.push('NovaCotacao');
      }
      if (item == 'Pré Cotação') {
        this.$router.push('NovaPreCotacao');
      }
      console.log(item);
    },
    open() {
      this.isOpen = true;
      this.color = 'red';
      this.invisibleClass = 'invisible';
    },
    close() {
      this.isOpen = false;
      this.color = '#348aa7';
      this.invisibleClass = '';
    },
  },
  mounted() {},
  components: { RadialMenu, RadialMenuItem },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-bottom {
  z-index: 9999;
}
.icone {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  font-size: 28px;
}
.btnplus {
  margin-left: 27%;
  width: 60px;
  height: 60px;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  /*position: relative;*/
}
.backdiv {
  background-color: #343a40;
  height: 170px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.invisible {
  visibility: hidden;
}
.vue-radial-menu-item {
  margin-left: -10px;
}
.vue-radial-menu-item span.texto {
  font-size: 13px;
  font-weight: normal;
}
.vue-radial-menu-item span.icone {
  font-size: 15px;
  font-weight: normal;
  display: block;
}
</style>
